.cookie-banner {
  z-index: 1;

  .card {
    border: none; }

  .card-body {
    background-color: var(--bs-dark);
    color: var(--bs-light);
    padding: .5rem .5rem;
    font-size: 16px;
 }    // transition: height 1s ease

  span {
    line-height: 38px; }

  a {
    color: var(--bs-light);
    &.cookie-banner-agree {
      color: var(--bs-dark);
      float: right; }
    &.cookie-banner-opt-out {
      float: right;
      &:hover {
        color: var(--bs-warning); } } } }
