article.article-blog-preview,
article.article-project-preview {
	margin-bottom: 2rem;

	a {
		text-decoration: none;
		font-weight: normal !important;

		&:hover {
			color: inherit; }

		&.btn {
			color: var(--bs-light); } }

	.read-more {
		text-align: left; } }

.blog-card,
.index-article-preview {
	padding-top: 10px;
	padding-bottom: 10px;
	padding-left: 15px;
	padding-right: 15px;
	box-shadow: 0 .125rem .25rem rgba(0,0,0,.075);
	border: 1px solid rgba(0,0,0,.075);
	border-radius: 4px;

	a {
		font-weight: normal; } }

.index-article-preview {
	margin-bottom: 1rem; }

article.article-project,
article.article-blog,
section.page-markdown {
	p,
	li {
		font-size: 21px;
		line-height: 32px; }

	ul > li > ul {
		margin-top: 0; }

	p,
	blockquote,
	ul,
	ol {
		margin-top: 2rem; }

	p > img {
		width: 75%;
		display: block;
		margin: 0 auto;
		box-shadow: 0 .125rem .25rem rgba(0,0,0,.075);
		border: 1px solid rgba(0,0,0,.075);
		border-radius: 4px; }


	h1, h2, h3, h4, h5, h6 {
		margin-top: 2.5rem;
		margin-bottom: -1.5rem; }

	p:first-child {
		font-size: 24px;
		font-weight: 300;
		margin-top: 1rem; }

	a {
		color: var(--bs-dark);
		text-decoration: none;
		font-weight: 500;
		&:hover {
			color: $color-space-yellow-variant !important; } }

	a:hover h2 {
		color: $color-space-yellow-variant !important; } }

//div.header-blog-metadata
//adding-left: 5px

.commento-footer {
	display: none; }
