#footer {
	background-color: var(--bs-dark);
	color: var(--bs-light);
	padding-bottom: 3rem;
	margin-top: 3rem;
	font-size: 16px;

	img {
		height: 64px;
		width: auto;
		padding-right: 15px; }

	h2 > span {
		font-size: 20px;
		margin-top: 3px; }

	h3 {
		font-size: 20px; }

	ul {
		list-style: none;
		padding-left: 0; }

	ul.socials {
		padding-left: 79px;

		> li {
			display: inline;
			margin-right: 1rem;

			> a {
				font-size: 24px; } } }

	ul > li > a,
	.contact a {
		color: var(--bs-light) !important;
		text-decoration: none;
		font-weight: normal;

		&:hover {
			color: $color-space-yellow-variant !important; } } }
