#news {
	h2 {
		margin-bottom: 2rem; }

	article {
		padding-top: 8px;

		a {
			text-decoration: none;
			color: var(--bs-grey); } } }
