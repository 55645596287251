div.highlighter-rouge {
	margin-top: 2rem;
	margin-bottom: 1rem;

	> .highlight {
		border-radius: 4px; } }

.highlight {
	padding: 10px;
	margin-bottom: 0; }

code.highlighter-rouge {
	background-color: #272822;
	color: #f8f8f2;

	border-radius: 4px;
	padding-left: 5px;
	padding-right: 5px;
	padding-top: 2px;
	padding-bottom: 2px; }
