#calendar {

	a {
		font-weight: normal; }

	.fc-list-day-text {
		text-decoration: none;
		color: var(--bs-grey); }

	.fc-button-primary {
		background-color: $color-space-blue;
		&:hover {
			background-color: darken($color-space-blue, 5%); } }

	.fc-list-event-dot {
		border-color: $color-space-blue; } }
