body {
	font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif;
	font-size: 18px;

	display: flex;
	flex-direction: column;

	h1, h2, h3, h4, h5, h6 {
		font-weight: 900; } }

body, html {
  height: 100%; }

.spacer {
  flex: 1; }

span.brand {
	font-weight: 900;
	letter-spacing: 1px; }

a {
	color: var(--bs-dark);
	text-decoration: none;
	font-weight: bold;
	&:hover {
		color: $color-space-yellow-variant; } }

.border-space-blue {
	border-color: $color-space-blue !important; }

.bg-space-blue {
	background-color: $color-space-blue !important;
	color: var(--bs-light);

	&:hover {
		color: var(--bs-light); } }

.bg-space-orange {
	background-color: $color-space-orange !important; }

.bg-space-yellow {
	background-color: $color-space-yellow !important; }

.btn-outline-space-blue {
	color: $color-space-blue;
	border-color: $color-space-blue;

	&:hover {
		background-color: $color-space-blue !important;
		color: var(--bs-light);
		border-color: $color-space-blue; } }

.page-header {
	padding-top: 11rem;
	padding-bottom: 10rem;
	background-color: var(--bs-dark);
	color: var(--bs-light);
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	margin-top: -100px;
	position: relative; }

@media (max-width: 991.98px) {
	.page-header {
		padding-top: 3rem !important;
		padding-bottom: 3rem !important;
		margin-top: 0 !important; }

	.page-header h1 {
		font-size: 48px !important; } }

.page-header .header-content {
	z-index: 1;
	position: relative; }

.page-header .overlay {
	position: absolute;
	top: 0;
	width: 100%;
	height: 100%;
	background-color: var(--bs-dark);
	opacity: .55; }

.header-condensed {
	padding-top: 7rem;
	padding-bottom: 5rem; }

.page-header h1 {
	font-weight: 900;
	font-size: 72px;
	max-width: 600px; }

.header-condensed h1 {
	max-width: 100%; }

.page-section {
	padding-top: 3rem; }

.page-section p > img {
	width: 50%; }

@media (max-width: 991.98px) {
	.page-section p > img {
		width: 100%; } }

#introduction.page-section img,
#vision.page-section img,
#signup.page-section img,
#signup-img.page-section img {
	width: 100%;
	border-radius: 4px; }

.page-banner {
	padding: 2rem;
	/*border: 1px solid var(--bs-light);*/
	border-radius: 4px;
	font-size: 18px;
	color: var(--bs-light); }

.page-banner-default {
	background-color: var(--bs-light);
	color: var(--bs-dark); }

.page-banner-warning {
	background-color: var(--bs-danger); }

.page-banner-success {
	background-color: var(--bs-success); }

.page-banner-discord {
	background-color: #7289DA; }

.page-banner a {
	color: inherit;
	font-weight: bold;
	text-decoration: none;
	&:hover {
		color: darkgrey; } }

.page-header-actueel {
	padding: 1em;
	background-color: white;
	border-radius: 5px;
	margin-top: 1rem;
	text-align: center;

	.btn {
		font-size: 18px; } }

@media (max-width: 767.98px) {
	.header-actueel-content {
		width: 100%; } }

.page-header-actueel h2 {
	color: black; }

.page-header-actueel a {
	margin-bottom: 0.5em; }

.btn-discord {
	background-color: #7289DA;
	color: white; }

.btn-discord:hover {
	color: var(--bs-light);
	background-color: #5769A6; }

.btn-signup {
	background-color: #e8c009;
	color: black; }

.btn-signup:hover {
	color: var(--bs-light);
	background-color: #e28905; }


.pricing-card-title {
	font-weight: inherit; }
