nav.navbar {
	z-index: 1;
	font-size: 18px;
	background-color: transparent !important; }

nav img {
	height: 64px;
	padding-right: 25px; }

nav ul.navbar-nav {
	align-items: center; }

nav a {
	font-weight: normal; }

@media (max-width: 991.98px) {
	nav.navbar {
		background-color: var(--bs-dark) !important; }
	nav ul.navbar-nav {
		align-items: start; }
	nav img {
		height: 40px;
		padding-right: 10; } }

nav .nav-item:last-of-type {
	margin-left: .5rem;
	border: 1px solid var(--bs-light);
	border-radius: 4px; }

nav .nav-item:last-of-type a {
	color: var(--bs-light) !important; }

nav .nav-item:last-of-type:hover {
	background-color: var(--bs-light);

	a {
		color: var(--bs-dark) !important; } }
